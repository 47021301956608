var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.api.isLoading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-row',_vm._l((_vm.data),function(question){return _c('v-col',{key:question.id,attrs:{"cols":"12","sm":"4"}},[_c('v-card',{style:(_vm.selectedQuestionsKeys.includes(question.key)
              ? 'border: 2px solid #1976D2;'
              : ''),attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v(_vm._s(question.name))]),(question.question_code)?_c('div',[_c('v-divider'),_c('v-chip',{staticClass:"ma-1",staticStyle:{"float":"right"},attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.$t('string.sample_question')))]),_c('div',{staticClass:"pa-3",staticStyle:{"min-height":"180px"}},[_c('div',{staticStyle:{"-webkit-transform":"scale(0.8)","-moz-transform":"scale(0.8)","-ms-transform":"scale(0.8)","transform":"scale(0.8)"},domProps:{"innerHTML":_vm._s(question.question_code.question)}})]),_c('v-divider')],1):_vm._e(),_c('v-spacer'),_c('div',{class:_vm.selectedQuestionsKeys.length == 15 &&
              !_vm.selectedQuestionsKeys.includes(question.key)
                ? 'd-flex justify-center align-center'
                : 'd-flex justify-center align-center checkbox'},[_c('v-checkbox',{attrs:{"label":_vm.selectedQuestionsKeys.includes(question.key)
                  ? _vm.$t('action.selected')
                  : _vm.$t('action.select'),"value":question.key,"disabled":_vm.selectedQuestionsKeys.length == 15 &&
                !_vm.selectedQuestionsKeys.includes(question.key)
                  ? true
                  : false},on:{"click":function($event){return _vm.get(question, question.key)}},model:{value:(_vm.selectedQuestionsKeys),callback:function ($$v) {_vm.selectedQuestionsKeys=$$v},expression:"selectedQuestionsKeys"}})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }