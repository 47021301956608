<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <PageManage
      :role="role"
      :model="model"
      :url="`${$api.servers.event}/api/v1/${this.$_getLocale()}/moderator/event/${$route.params.grandParentId}/schoolYear/${$route.params.parentId}/topic/add`"
      action="add"
      :callbackSuccess="callbackSuccess"
      :items="items"
      :old="old"
      :oldKeys="oldKeys"
      :oldChapterKeys="oldChapterKeys"
      :length="length"
    ></PageManage>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import modelGrandParent from '@/models/items/event'
import modelParent from "@/models/items/eventSchoolYear";
import model from "@/models/items/eventTopic";
//EOC
import PageManage from "@/components/Moderator/Topic/PageManage";
import { mapState } from "vuex";
import axios from "axios";
export default {
  components: {
    PageManage,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["parent",'grandParent'],
  data: () => ({
    items: [],
    filter: [],
    old: [],
    oldKeys: [],
    oldChapterKeys: [],
    length: 0,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    modelGrandParent: modelGrandParent,
    modelParent: modelParent,
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
         this.length = resp.length*6.67
      for (var i = 0, l = this.items.length; i < l; i++) {
        
              this.oldKeys.push(this.items[i].questionKey);
        this.filterItems(this.items[i].chapterKey, this.items[i].questionKey);
      }
    };
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text:this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelGrandParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelGrandParent.key),
        exact:true,
      })
    //
    this.breadcrumbs.push({
        text:(this.grandParent && this.grandParent.name) ? this.grandParent.name : `${this.$t("model.name." + this.modelGrandParent.name.singular.toLowerCase())} ${this.$route.params.grandParentId}`,
        to:this.$_getRouteRead(this.role,this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent),
        exact:true,
      })

      let grandParentPath = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
       grandParentPath.query = {tab:'SchoolYear'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:grandParentPath,
        exact:true,
      })
   
    //
    this.breadcrumbs.push({
        text:(this.parent) ? this.parent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteChildRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent, this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent),
        exact:true,
      })
       //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'EventTopic'}
     
      //
    this.breadcrumbs.push({
      text: `${this.$t("string.bread_manage")}${this.$t("model.name." + this.model.name.plural.toLowerCase())}`,
      to: {
        name: "PageModeratorEventEventSchoolYearTopicManage",
        params: { parentId: this.$route.params.parentId, parent: this.parent },
      },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.event +
        "/api/v1/"+this.$_getLocale()+"/moderator/event/" +this.$route.params.grandParentId +"/schoolYear/"+
        this.$route.params.parentId +
        "/topic";
      this.$api.fetch(this.api);
    },
    filterItems(chapterKey, questionKey) {
      axios
        .get(
          this.$api.servers.question +
            "/v2/chapters/" +
            chapterKey +
            "/questions?lang=" +
            this.$_getLocale()
        )
        .then((response) => {
          this.filter = response.data;
          for (var j = 0, k = this.filter.length; j < k; j++) {
            if (this.filter[j].key == questionKey) {
              this.old.push(this.filter[j]);
              this.oldChapterKeys.push({
                chapterKey: chapterKey,
                questionKey: this.filter[j].key,
              });
            }
          }
          this.old.sort((a, b) => (a.key > b.key) ? 1 : -1)
        });
    },
  
    callbackSuccess() {
        let redirect = this.$_getRouteChildRead(this.role,this.modelParent.key,this.$route.params.parentId,this.myParent, this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent)
        redirect.query = {tab:this.model.key}
        this.$router.push(redirect)
      },
  },

  beforeMount() {
    this.fetch();

    this.filterItems();
  },
};
</script>