<template>
  <v-container>
    <div v-if="api.isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col v-for="question in questions" :key="question.id" cols="12" sm="4">
        <v-card
          height="100%"
          :style="
            selected.includes(question) ? 'border: 2px solid #1976D2;' : ''
          "
        >
          <v-card-title>{{ question.name }}</v-card-title>
          <div v-if="question.question_code">
            <v-divider></v-divider>
            <v-chip x-small class="ma-1" style="float: right"
              >{{$t('string.sample_question')}}</v-chip
            >
            <div class="pa-3" style="min-height: 180px">
              <div
                style="
                  -webkit-transform: scale(0.8);
                  -moz-transform: scale(0.8);
                  -ms-transform: scale(0.8);
                  transform: scale(0.8);
                "
                v-html="question.question_code.question"
              ></div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex justify-center align-center py-3">
              <v-btn @click="remove(question)" color="red" text
                >{{$t("action.remove")}}</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["callbackError", "chapter", "callbackRemove", "questions"],
  data: () => ({
    checkbox: true,
    selected: [],
    dialogs: {
      view: false,
    },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  components: {},
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
    //EOC
  },
  methods: {
    remove(key) {
      let index = this.questions.indexOf(key);
      this.questions.splice(index, 1);
      this.callbackRemove(key);
    },
    fetch() {
      this.api.url =
        this.$api.servers.event +
        "/api/v1/"+this.$_getLocale()+"/moderator/event/" +
        this.$route.params.parentId +
        "/topic";
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>
<style>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
v-checkbox {
  align-items: center;
  justify-content: center;
}
</style>
