<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-row class="mb-3"> <h1>{{$t("model.name.questions")}}</h1></v-row>
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-row>
        <v-col cols="12" md="6">
          <v-progress-linear
            :value="count == 0 && removeCount == 0 ? length : count"
            height="25"
          >
            <strong>{{ id.length }}/15</strong>
          </v-progress-linear>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <!-- <v-btn elevation="2" class="mr-3"  :to="{ name: 'Page'+role+'EventCheckpointTemplate'}"> {{$t("action.view_templates")}} </v-btn> -->
          <v-btn
          class="mr-3"
            :loading="api.isLoading"
            @click="submit"
            elevation="2"
            color="primary"
            :disabled="id.length == 0"
          >
            {{$t("action.save")}}
          </v-btn>
          <v-btn
          text
          @click="$router.go(-1)"
        >
          {{$t("action.cancel")}}
        </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-tabs>
          <v-tab>{{$t("model.filterBy.all")}}</v-tab>
          <v-tab>{{$t("action.selected")}}</v-tab>
          <v-tab-item>
            <v-row class="mt-1">
              <v-col cols="12" md="6">
                <Syllabus
                  ref="syllabus"
                  :callbackSelect="selectSyllabus"
                  :callbackError="showError"
                />
              </v-col>
              <v-col cols="12" md="6">
                <Chapter
                  ref="chapter"
                  :syllabus="syllabus"
                  :callbackSelect="selectChapter"
                  :callbackError="showError"
                  @input="form.chapterKey = $event"
                />
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <div v-if="chapter">
              <h3 class="mb-3">{{$t("model.name.chapter")}} {{ chapter.sort }}</h3>
              <h1 class="mb-3">{{ chapter.name }}</h1>
              <GridQuestion
                ref="grid_question"
                :chapter="chapter"
                :callbackSelect="selectQuestion"
                :callbackError="showError"
                :old="old"
                :oldKeys="oldKeys"
                :oldChapterKeys="oldChapterKeys"
              ></GridQuestion>
            </div>
          </v-tab-item>
          <v-tab-item>
            <SelectedQuestion
              :callbackRemove="removeQuestion"
              :questions="question"
              :ids="id"
            />
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-form>
  </v-container>
</template>



<script>
import Chapter from "@/components/Moderator/Topic/Chapter";
import Syllabus from "@/components/Moderator/Topic/Syllabus";
import GridQuestion from "@/components/Moderator/Topic/GridQuestion";
import SelectedQuestion from "@/components/Moderator/Topic/SelectedQuestion";
export default {
  props: [
    "items",
    "old",
    "self",
    "role",
    "model",
    "url",
    "action",
    "callbackSuccess",
    "oldKeys",
    "oldChapterKeys",
    "length",
  ],
  data: () => ({
    syllabus:{},
    count: 0,
    chapter: {},
    question: [],
    id: [],
    removeCount: 0,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    form: {},
  }),
  components: {
    Chapter,
    Syllabus,
    GridQuestion,
    SelectedQuestion,
  },
  created() {
    this.question = this.old;
    this.form = this.oldChapterKeys;
    this.id = this.oldKeys;
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },

  methods: {
    showError(error) {
      this.api.isError = true;
      this.api.error = error;
    },
    selectSyllabus(item) {
      this.syllabus = item;
      var that = this;
      setTimeout(() => {
        that.$refs["chapter"].fetch();
      }, 100);
    },
    selectChapter(item) {
      this.chapter = item;
      this.chapterKey = null;
      var that = this;
      setTimeout(() => {
        that.$refs["grid_question"].fetch();
      }, 100);
    },

    selectQuestion(questions, ids, filter) {
      this.length = 0;
      this.question = questions.sort((a, b) => (a.key > b.key) ? 1 : -1);
      this.id = ids;
      this.count = this.id.length * 6.67;
      this.form = filter;
      //this.chapterKey =null
    },

    removeQuestion(key) {
      let index = this.id.indexOf(key.key);
      this.id.splice(index, 1);
      this.count = this.id.length * 6.67;
      this.removeCount = 1;

      for (var i = 0, l = this.form.length; i < l; i++) {
        if (this.form[i].questionKey == key.key) {
          this.form.splice(i, 1);
        }
      }
    },

    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {},
};
</script>

<style>
</style>