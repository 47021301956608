<template>
<v-container>
    <div v-if="api.isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col v-for="question in data" :key="question.id" cols="12" sm="4">
        <v-card
          height="100%"
          :style="
            selectedQuestionsKeys.includes(question.key)
              ? 'border: 2px solid #1976D2;'
              : ''
          "
        >
          <v-card-title>{{ question.name }}</v-card-title>
          <div v-if="question.question_code">
            <v-divider></v-divider>
            <v-chip x-small class="ma-1" style="float: right"
              >{{$t('string.sample_question')}}</v-chip
            >
            <div class="pa-3" style="min-height: 180px">
              <div
                style="
                  -webkit-transform: scale(0.8);
                  -moz-transform: scale(0.8);
                  -ms-transform: scale(0.8);
                  transform: scale(0.8);
                "
                v-html="question.question_code.question"
              ></div>
            </div>
            <v-divider></v-divider>
          </div>
          <v-spacer></v-spacer>
          <div
            :class="
              selectedQuestionsKeys.length == 15 &&
              !selectedQuestionsKeys.includes(question.key)
                ? 'd-flex justify-center align-center'
                : 'd-flex justify-center align-center checkbox'
            "
          >
            <v-checkbox
              @click="get(question, question.key)"
              v-model="selectedQuestionsKeys"
              :label="
                selectedQuestionsKeys.includes(question.key)
                  ? $t('action.selected')
                  : $t('action.select')
              "
              :value="question.key"
              :disabled="
                selectedQuestionsKeys.length == 15 &&
                !selectedQuestionsKeys.includes(question.key)
                  ? true
                  : false
              "
            ></v-checkbox>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["callbackError", "chapter", "callbackSelect", "old", "oldKeys","oldChapterKeys"],
  data: () => ({
    checkbox: true,
    selectedQuestionsKeys: [],
    selectedQuestions: [],
    selectedKeys: [],
    selectedQuestionsFilter: [],
    selectedQuestionsFilterForm: [],
    selectedChapterKeys: [],
    dialogs: {
      view: false,
    },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  components: {},
  created() {
    this.selectedQuestionsKeys = this.oldKeys;
    this.selectedQuestions = this.old;
    this.selectedKeys = this.oldKeys;
    this.selectedQuestionsFilter = this.old;
    this.selectedQuestionsFilterForm = this.oldChapterKeys;
     this.selectedChapterKeys = this.oldChapterKeys;
  
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
    //EOC
  },
  methods: {
    get(question, key) {
      if (!this.selectedQuestions.includes(question) && !this.selectedKeys.includes(key) ) {
        this.selectedQuestions.push(question);
        this.selectedKeys.push(key);
        this.selectedChapterKeys.push({
          chapterKey: this.chapter.key,
          questionKey: question.key,
        });
      }

      this.selectedQuestionsFilter = [];
      this.selectedQuestionsFilterForm = [];

      for (var i = 0, l = this.selectedQuestions.length; i < l; i++) {
        if (this.selectedQuestionsKeys.includes(this.selectedQuestions[i].key)) {
          this.selectedQuestionsFilter.push(this.selectedQuestions[i]);
        }
        if (
          this.selectedQuestionsKeys.includes(
            this.selectedChapterKeys[i].questionKey
          )
        ) {
          this.selectedQuestionsFilterForm.push(this.selectedChapterKeys[i]);
        }
      }

      this.callbackSelect(
        this.selectedQuestionsFilter,
        this.selectedQuestionsKeys,
        this.selectedQuestionsFilterForm
      );
    },
    fetch() {
      this.api.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        this.chapter.key +
        "/questions?lang=" +
        this.$_getLocale();
      this.$api.fetch(this.api);
    },
  },
};
</script>
<style>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
v-checkbox {
  align-items: center;
  justify-content: center;
}
.checkbox label {
  color: #1976d2 !important;
}
</style>

