<template>
  <v-select
    :label='$t("model.name.chapter")'
    class="white"
    :items="data"
    :item-text="chapterised"
    item-value="key"
    v-model="key"
    :loading="api.isLoading"
    placeholder="Chapter"
    outlined
  ></v-select>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError", "syllabus"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    key: null,
    item: null,
  }),
computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.key = resp[0].key;
    };
    //EOC
  },
  watch: {
    key: function (val) {
      this.key = val;
      this.item = this.$_.find(this.data, { key: this.key });
      this.callbackSelect(this.item);
    },
  },
  methods: {
   fetch() {
      this.api.url =
        this.$api.servers.question +
        "/v2/syllabi/" +
        this.syllabus.key +
        "/chapters?lang=" +
        this.$_getLocale();
      this.$api.fetch(this.api);
    },
    chapterised: (item) => item.sort + ". " + item.name,
  },
};
</script>
